import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: []
		};
	},
	// props: {
	// 	filter: Object,
	// 	sorting: String,
	// 	max: String
	// },
	beforeMount: function () {
		const url = window.location.href;

		const from = this.getParameterByName('from', url);
		const till = this.getParameterByName('till', url);
		const petsCount = this.getParameterByName('petsCount', url);
		var pets = this.getParameterByName('pets', url);
		const adults = this.getParameterByName('adults', url);
		const children = this.getParameterByName('children', url);

		var persons = parseInt(adults) + parseInt(children);

		//console.log('petsCount:', petsCount, 'pets:', pets);
		//console.log('url:', url);

		var results = this.$el.getElementsByClassName('unit-list-section');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}
		var beds = 2;
		let el = document.querySelector("[data-beds]");
		if (el) {
			beds = parseInt(el.getAttribute('data-beds'), 10);
		}
		let e2 = document.querySelector("[data-region-id]");
		var regionId;
		if (e2) {
			regionId = parseInt(e2.getAttribute('data-region-id'));
		}
		let e3 = document.querySelector("[data-zaun]");
		var zaun;
		if (e3) {
			zaun = e3.getAttribute('data-zaun');
		}

		var filter = {};

		if (pets || petsCount > 0) {
			pets = true;
			filter.pets = true;
			if (zaun) {
				filter.p_14400 = true;
			}
		}

		if (!till && !from && regionId) {
			filter['region.id'] = regionId;
		}

		if (till && from) {
			filter.from = from;
			filter.till = till;
		}

		if (beds && persons > 2) {
			filter.beds = beds;
		}

		var req;


		req = {
			fields: this.resultFields,
			filter: filter,
			max: 6,
			sorting: 'random'
		};

		//console.log('Requet::', req);

		doReq('searchUnits', req)
			.then((res) => {
				this.units = res.units;

			});


	},
	updated: function () {
		this.applySlider();
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},
	methods: {
		getAvgStars: function (stars) {
			var round = Math.round(stars / 0.5) * 0.5;
			return round;
		},
		getParameterByName: function (name, url) {
			if (!url) url = window.location.href;
			name = name.replace(/[\[\]]/g, '\\$&');
			var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
				results = regex.exec(url);
			if (!results) return null;
			if (!results[2]) return '';
			return decodeURIComponent(results[2].replace(/\+/g, ' '));
		},
		getUnitSearchTarget: function (id) {
			if (window.screen.width < 768) {
				//probably phone?
				return '_self';
			} else {
				return 'unit_' + id;
			}
		},


		applySlider: function () {
			$(".owl-carousel.similar-units").owlCarousel({
				loop: false,
				margin: 30,
				smartSpeed: 3000,
				autoplay: false,
				nav: true,
				responsive: {
					0: {
						items: 1
					},
					768: {
						items: 2
					},
					996: {
						items: 3
					},
					1200: {
						items: 4

					}
				}
			});
		}
	}

};